<template>
  <div class="arrematante-analises-analise">
    <e-row>
      <e-col>
        <erp-s-field view="tl" label="Data da Análise">
          <strong>{{analise.createdAt|formatDate}}</strong>
        </erp-s-field>
      </e-col>
      <e-col>
        <erp-s-field view="tl" label="Operador">
          <strong>{{analise.createdByName}}</strong>
        </erp-s-field>
      </e-col>
      <e-col>
        <erp-s-field view="tl" label="Tipo">
          <strong class="text-uppercase">{{analise.tipo}}</strong>
        </erp-s-field>
      </e-col>
    </e-row>
    <e-row v-if="analise.tipo === 'cadastro'" class="m-t">
      <template v-if="analise.analise && Array.isArray(analise.analise)">
        <div class="analise-cadastro">
          <div v-for="(a, key) in analise.analise" :key="'ac-' + key" class="item">
            <div class="a-alert">
              {{a.alerta}}
            </div>
            <div class="pessoa-rel">
              <div class="m-b-xs">Pessoa relacionada:</div>
              <div><a @click="arrematanteWindow(a.pessoa.arrematante.id)" class="text-black_ font-bold">{{a.pessoa.name}}</a></div>
              <div>{{a.pessoa.document|formataCpfCnpj2}}</div>
              <status-arrematante :arrematante="a.pessoa.arrematante" />
            </div>
          </div>
        </div>
      </template>
    </e-row>
    <e-row v-else class="m-t">
      <e-col>
        <erp-s-field view="tl" label="Resultado">
          <pre>{{analise.analise}}</pre>
        </erp-s-field>
      </e-col>
    </e-row>
  </div>
</template>

<script>
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import StatusArrematante from "components/arrematante/components/include/StatusArrematante.vue"
import arrematanteWindow from '@/components/arrematante/windows/arrematante'
export default {
  name: 'ArrematanteAnaliseContainer',
  props: {
    analise: {required: true}
  },
  data () {
    return {
    }
  },
  mounted() {
  },
  methods: {
    arrematanteWindow
  },
  components: {
    StatusArrematante,
    ErpSField,
    ERow,
    ECol
  }
}
</script>

<template>
  <div class="m-t-lg">
    <e-row>
      <e-col>
        <p class="font-bold m-b-xs font-13">{{ label }}
          <u-btn @click="load" icon="refresh" round rounded flat size="xs" :loading="table.loading"/>
        </p>
      </e-col>
      <e-col class="text-right">
      </e-col>
    </e-row>
    <div class="erp-list" :class="{'enableScroll': scroll}">
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-table"
          :rows-per-page-options="[100, 300, 500]"
      >
        <!--<template slot="top-right" slot-scope="props">
        </template>-->
        <u-tr slot="header" slot-scope="props">
          <u-th :style="col.name === 'lote' ? 'max-width: 25px !important' : ''" v-for="col in props.cols"
                :key="col.name" :props="props">
            {{ col.label }}
          </u-th>
        </u-tr>

        <u-tr class="cursor-pointer"
              slot="body" slot-scope="props"
              :props="props"
        @dblclick.native="lanceWindow(props.row)">
          <u-td key="data" :props="props">
              {{ props.row.data|formatDate('dd/MM/yyyy HH:mm:ss') }}
          </u-td>
          <u-td key="leilao" :props="props">
            <div v-if="props.row.lote && props.row.lote.leilao">
              {{ props.row.lote.leilao.codigo }}
            </div>
          </u-td>
          <u-td key="lote" :props="props">
            <div v-if="props.row.lote">
              {{ props.row.lote.numero }}
            </div>
          </u-td>
          <u-td key="valor" :props="props">
            R$ {{ props.row.valor|moeda }}
          </u-td>
          <u-td key="status" :props="props">
            <div v-if="props.row.status == '0'" class="text-negative">Cancelado</div>
            <div v-if="props.row.status == '1'">Vencendo</div>
            <div v-if="props.row.status == '3'">Superado</div>
            <div v-if="props.row.status == '99'">Reprovado</div>
            <div v-if="props.row.status == '100'" class="text-positive">Vencedor</div>
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <e-btn-table-options>
              <menu-options>
                <ul>
                  <menu-options-item close label="Ver lote" @click="abrir(props.row.lote)" icon="folder-open"/>
                  <menu-options-item close label="Ver Lance" @click="lanceWindow(props.row)" icon="folder-open"/>
                </ul>
              </menu-options>
            </e-btn-table-options>
          </u-td>
          <!--<context-menu-pendencias-item @abrir="abrir(props.row)" :leilao="props.row" />-->
        </u-tr>
      </u-table>
    </div>
  </div>
</template>

<script>
import ERow from '@/components/layout/components/Row'
import ECol from '@/components/layout/components/Col'
import MenuOptions from '@/components/layout/context-menu/context-window-options'
import {UCheckbox, UTable, UTd, UTh, UTr} from 'uloc-vue'
import {EBtnTableOptions} from 'uloc-vue-plugin-erp'
import {list} from '@/domain/leiloes/services/lances'
import loteWindow from '@/components/leiloes/windows/lote'
import lanceWindow from '@/components/leiloes/windows/lance'
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'

export default {
  name: 'HistoricoLances',
  props: {
    arrematante: {required: true},
    label: {
      default: 'Pendências'
    },
    scroll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10,
          rowsPerPage: 50,
          sortBy: 'data',
          descending: true
        },
        columns: [
          {name: 'data', label: 'Data', field: 'data', align: 'left', sortable: true, active: true},
          {
            name: 'leilao',
            required: true,
            label: 'Leilão',
            align: 'left',
            field: 'leilao',
            sortable: true,
            active: true
          },
          {name: 'lote', label: 'LT', field: 'lote', align: 'left', sortable: true, active: true},
          {
            name: 'valor',
            required: true,
            label: 'Valor',
            align: 'left',
            field: 'valor',
            sortable: true,
            active: true
          },
          {
            name: 'status',
            required: true,
            label: 'Situação',
            align: 'left',
            field: 'status',
            sortable: false,
            active: true
          },
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false,
            active: true
          }
        ],
        filter: '',
        selected: [],
        loading: false
      }
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    visibledColumns() {
      return this.table.columns.filter(item => item.active).map(item => item.name)
    },
  },
  components: {
    MenuOptionsItem,
    ECol,
    ERow,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    //UCheckbox,
    EBtnTableOptions
  },
  methods: {
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca

      })
    },
    request({pagination, filter}) {
      this.table.loading = true

      const extraFilters = []
      extraFilters.push('arrematante=' + this.arrematante.id)
      let filters = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&search=${filter}&${extraFilters.join('&')}`)
      list(pagination.rowsPerPage, pagination.page, filters)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = this.proccessColumns(data.result)

            this.table.loading = false
          })
          .catch(error => {
            this.table.loading = false
          })
    },
    proccessColumns(data) {
     /* if (Array.isArray(data) && data.length > 0) {
        data.slice().map(venda => {
          let lote = venda.lote || venda.lanceCancelado.lote
          if (lote && lote.bem && lote.bem.placa) {
            let c = this.table.columns.find(c => c.name === 'placa')
            if (c && !c.active) {
              console.log('Existe placa')
              c.active = true
            }
          }
          console.log(lote)
        })
      }*/
      return data
    },
    abrir(lote) {
      this.loteWindow(lote)
    },
    loteWindow: loteWindow,
    lanceWindow,
  }
}
</script>

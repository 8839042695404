<template>
  <div>
    <endereco-v2 class="wrapper" :pessoa="entidade.pessoa" />
    <div class="wrapper e-input-modern label-size-1 size1">
      <collapse :collapse="true" title="Contatos">
      <e-row mr>
        <e-col>
          <e-row v-for="(telefone, index) in entidade.pessoa.phoneNumbers" :key="telefone.id">
            <e-col>
              <e-row v-if="index === 0" mr>
                <e-col style="max-width: 280px">
                  <erp-s-field
                      view="lr"
                      label=""
                      label-width="90px"
                  >
                    <div class="font-11 font-bold m-b">Telefones</div>
                  </erp-s-field>
                </e-col>
              </e-row>
              <e-row v-if="index > 2" class="m-b-sm" mr>
                <e-col style="max-width: 280px">
                  <tipo-telefone-select field-view="lr" label-width="90px" v-model="telefone.purpose" />
                </e-col>
                <e-col class="flex items-center">
                  <a class="text-negative" @click="removeTelefone(telefone)">remover este telefone</a>
                </e-col>
              </e-row>
              <e-row class="m-t-n" mr>
                <e-col style="max-width: 280px">
                  <erp-s-field
                      view="lr"
                      :label="getTelefoneLabel(index)"
                      label-width="90px"
                  >
                    <erp-input v-if="telefone.cellphone && isBrasileiro" size="2" v-model="telefone.phoneNumber" v-mask="'(##) # ####-####'" />
                    <erp-input v-else size="2" v-model="telefone.phoneNumber" />
                  </erp-s-field>
                </e-col>
              </e-row>
            </e-col>
          </e-row>
          <e-btn @click="adicionarTelefone" style="margin-left: 102px" label="Adicionar novo telefone" />
          <e-btn @click="editarTelefones = !editarTelefones" style="margin-left: 102px" label="Editar telefones" />
          <!-- -->
          <e-col>
            <e-row class="m-t-lg" mr>
              <e-col style="max-width: 280px">
                <erp-s-field
                    view="lr"
                    label=""
                    label-width="90px"
                >
                  <div class="font-11 font-bold">Outros contatos</div>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row v-for="(contato, index) in entidade.pessoa.contacts" :key="contato.id">
              <e-col>
                <e-row class="m-b-sm" mr>
                  <e-col style="max-width: 280px">
                    <tipo-contato-select field-view="lr" size="2" label="Tipo:" label-width="90px"
                                         v-model="contato.purpose" />
                  </e-col>
                  <e-col class="flex items-center">
                    <a class="text-negative" @click="removeContato(contato)">remover este contato</a>
                  </e-col>
                </e-row>
                <e-row class="m-t-n" mr>
                  <e-col style="max-width: 340px">
                    <erp-s-field
                        view="lr"
                        label="Contato"
                        label-width="90px"
                    >
                      <erp-input size="2" v-model="contato.value" />
                    </erp-s-field>
                  </e-col>
                </e-row>
              </e-col>
            </e-row>
            <e-btn @click="adicionarContato" style="margin-left: 102px" label="Adicionar novo contato" />
          </e-col>
        </e-col>
        <e-col>
          <e-row mr>
            <e-col style="max-width: 280px">
              <erp-s-field
                  view="lr"
                  label=""
                  label-width="90px"
              >
                <div class="font-11 font-bold m-b">E-mails</div>
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row class="m-t-n" mr>
            <e-col style="max-width: 340px">
              <erp-s-field
                  view="lr"
                  label="Principal:"
                  label-width="90px"
              >
                <erp-input size="2" v-model="entidade.pessoa.emails[0].email" />
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row v-for="(email, index) in entidade.pessoa.emails" :key="email.id">
            <e-col v-if="index > 0">
              <e-row class="m-b-sm" mr>
                <e-col style="max-width: 280px">
                  <tipo-email-select field-view="lr" size="2" label=" " label-width="90px" v-model="email.purpose" />
                </e-col>
                <e-col class="flex items-center">
                  <a class="text-negative" @click="removeEmail(email)">remover este email</a>
                </e-col>
              </e-row>
              <e-row class="m-t-n" mr>
                <e-col style="max-width: 340px">
                  <erp-s-field
                      view="lr"
                      label=""
                      label-width="90px"
                  >
                    <erp-input size="2" v-model="email.email" />
                  </erp-s-field>
                </e-col>
              </e-row>
            </e-col>
          </e-row>
          <e-btn @click="adicionarEmail" style="margin-left: 102px" label="Adicionar novo e-mail" />
        </e-col>
      </e-row>
      </collapse>
    </div>
  </div>
</template>

<script>
import {
  ErpSField,
  ErpInput,
} from 'uloc-vue-plugin-erp'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
import TipoTelefoneSelect from './TipoTelefoneSelect'
import {mockTelefone} from '../../mock/telefone'
import {mockEmail} from '../../mock/email'
import {mockContato} from '../../mock/contato'
import TipoEmailSelect from './TipoEmailSelect'
import TipoContatoSelect from './TipoContatoSelect'
import PersonMixin from './PersonMixin'
import EnderecoV2 from "components/pessoa/components/include/EnderecoV2"
import Collapse from "components/globalconfig/components/include/Collapse"
export default {
  name: 'Contato',
  mixins: [PersonMixin],
  components: {EnderecoV2, TipoContatoSelect, TipoEmailSelect, TipoTelefoneSelect, ECol, ERow, ErpSField, ErpInput, Collapse},
  props: ['entidade'],
  data () {
    return {
      editarTelefones: false
    }
  },
  computed: {
    isBrasileiro () {
      return !this.entidade.pessoa.nationality || String(this.entidade.pessoa.nationality).toLowerCase() === 'br' || String(this.entidade.pessoa.nationality).toLowerCase() === 'brasileiro'
    }
  },
  methods: {
    getTelefoneLabel (index) {
      if (index === 0) {
        return 'Celular:'
      } else if (index === 1) {
        return 'Residencial:'
      }
      if (index === 2) {
        return 'Comercial:'
      }
      return ''
    },
    adicionarTelefone () {
      this.entidade.pessoa.phoneNumbers.push(JSON.parse(JSON.stringify(mockTelefone)))
    },
    removeTelefone (telefone) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um telefone',
        message: 'Você tem certeza que deseja remover este telefone do cadastro desta pessoa? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        if (telefone.id) {
          this.removeItem('telefones', this.entidade.pessoa.id, telefone.id)
        }
        this.entidade.pessoa.phoneNumbers.splice(this.entidade.pessoa.phoneNumbers.indexOf(telefone), 1)
      }).catch(() => {
      })
    },
    adicionarEmail () {
      this.entidade.pessoa.emails.push(JSON.parse(JSON.stringify(mockEmail)))
    },
    removeEmail (email) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um e-mail',
        message: 'Você tem certeza que deseja remover este e-mail do cadastro desta pessoa? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        if (email.id) {
          this.removeItem('emails', this.entidade.pessoa.id, email.id)
        }
        this.entidade.pessoa.emails.splice(this.entidade.pessoa.emails.indexOf(email), 1)
      }).catch(() => {
      })
    },
    adicionarContato () {
      this.entidade.pessoa.contacts.push(JSON.parse(JSON.stringify(mockContato)))
    },
    removeContato (contato) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um contato',
        message: 'Você tem certeza que deseja remover este contato do cadastro desta pessoa? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        if (contato.id) {
          this.removeItem('contatos', this.entidade.pessoa.id, contato.id)
        }
        this.entidade.pessoa.contacts.splice(this.entidade.pessoa.contacts.indexOf(contato), 1)
      }).catch(() => {
      })
    }
  }
}
</script>

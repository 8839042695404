<template>
  <div class="mod-pessoa wrapper-md">
    <pessoa-abas :pessoa="arrematante.pessoa"/>
    <pessoa-planejamento :pessoa="arrematante.pessoa"/><!-- END Box -->
    <pessoa-historico :pessoa="arrematante.pessoa"/><!-- END Box -->
  </div>
</template>

<script>

import PessoaPlanejamento from "components/pessoa/components/include/pessoa/Planejamento.vue"
import PessoaHistorico from "components/pessoa/components/include/pessoa/Historico.vue"
import PessoaAbas from "components/pessoa/components/include/pessoa/PessoaAbas.vue"

export default {
  name: 'ArrematanteCrm',
  props: {
    arrematante: {
      required: true
    }
  },
  components: {
    PessoaAbas, PessoaHistorico, PessoaPlanejamento
  },
  data () {
    return {}
  },
  computed: {
  },
  methods: {
  }
}
</script>

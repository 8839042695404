import http from "@/utils/services/http";

export const findTiposPapelPessoa = (id) => {
    let url = `/api/tiposPapelPessoa/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const listTiposPapelPessoa = (limit, page, filtros) => {
    let url = '/api/tiposPapelPessoa?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newTipoNotificacao = (data) => {
    let url = `/api/tiposPapelPessoa`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateTipoNotificacao = (id, data) => {
    let url = `/api/tiposPapelPessoa/${id}`
    return http.put(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteTipoNotificacao = (id) => {
    let url = `/api/tiposPapelPessoa/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

<template>
  <div v-if="analises && analises.length" class="arrematante-analises">
    <h2>Análises de Segurança</h2>
    <arrematante-analise-container v-for="analise in analises" :key="analise.id" :analise="analise" />
  </div>
</template>

<script>
import {analisesCadastrais} from "@/domain/arrematante/services"
import ArrematanteAnaliseContainer from "components/arrematante/components/window/components/AnaliseContainer.vue"

export default {
  name: 'ArrematanteAnalises',
  components: {ArrematanteAnaliseContainer},
  props: {
    arrematante: {required: true}
  },
  data () {
    return {
      analises: []
    }
  },
  mounted() {
    this.carregaAnalisesCadastrais()
  },
  methods: {
    carregaAnalisesCadastrais() {
      analisesCadastrais(this.arrematante.id)
          .then(response => {
            this.analises = response.data
          })
          .catch(error => {
            console.log(error)
          })
    }
  }
}
</script>

<style lang="stylus" scoped>
.arrematante-analises {
  margin-top 20px
  margin-bottom 20px
  /* Gradiente linear */
  background: linear-gradient(to right, #f2f2f2, #ffffff, #f2f2f2);
  /* Para navegadores antigos */
  background: -webkit-linear-gradient(left, #f2f2f2, #ffffff, #f2f2f2);
  background: -moz-linear-gradient(left, #f2f2f2, #ffffff, #f2f2f2);
  background: -o-linear-gradient(left, #f2f2f2, #ffffff, #f2f2f2);
  padding 15px 20px
  border-radius 10px
  border #CCCCCC 1px solid
  h2 {
    font-size 22px
    color darkred
    margin-bottom 20px
  }
}
</style>

<template>
  <list-select :input-size="inputSize" :emit-only-value="false" ref="select" async :parse-callback="parse" v-on:input="(v) => $emit('input', v)" :search-data="search" v-model="model" />
</template>

<script>
import ListSelect from "@/reuse/input/ListSelect"
import {listTiposPapelPessoa as list} from "@/domain/cadastro/services/tiposPapelPessoa"
export default {
  name: "TipoPapelSelect",
  props: {
    value: {
      required: true
    },
    inputSize: {
      required: false
    },
    limit: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      model: this.value,
      result: []
    }
  },
  watch: {
    value (v) {
      this.$nextTick(() => {
        if (v !== this.model) {
          this.$refs.select.autoSelectValue(v)
        }
      })
    }
  },
  components: {ListSelect},
  methods: {
    parse (v) {
      return v && v.description
    },
    search (terms) {
      console.log('Find', this.model)
      return new Promise((resolve, reject) => {
        list(100, 1, '').then(response => {
              this.result = response.data
              if (!response.data || !response.data.result.length) {
                resolve([])
              }
              let list = response.data.result.map((obj) => {
                return {
                  ...obj,
                  value: obj.id,
                  label: obj.description
                }
              }).sort((a, b) => {
                if (a.description > b.description) {
                  return 1;
                }
                if (a.description < b.description) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              })
              if (this.limit && Array.isArray(this.limit) && this.limit.length) {
                list = list.filter(t => {
                  return this.limit.includes(t.code)
                })
              }
              resolve(list)
            })
            .catch(({response}) => {
              reject(response)
            })
      })
    }
  }
}
</script>

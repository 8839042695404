<template>
  <u-btn @click="analiseCadastral" :loading="loading" icon="security" label="Análise Cadastral" color="green" no-caps class="full-width" />
</template>

<script>
import ArrematanteExtraMixin from "components/arrematante/components/window/components/ArrematanteExtraMixin"
import {analisarCadastro} from "@/domain/arrematante/services"

export default {
  name: "ArrematanteExtra",
  mixins: [ArrematanteExtraMixin],
  data () {
    return {
      loading: false
    }
  },
  methods: {
    analiseCadastral() {
      this.$uloc.dialog({
        title: 'Executar Análise Cadastral',
        message: 'Você tem certeza que deseja iniciar?',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        this.loading = true
        console.log('X')
        console.log(this.arrematante)
        console.log(this.arrematante.id)
        analisarCadastro(this.arrematante.id)
            .then(response => {
              this.loading = false
              this.arrematanteContainer.load()
              console.log(response.data)
            })
            .catch(error => {
              this.loading = false
              this.alertApiError(error)
            })
      }).catch(() => {
      })
    }
  }
}
</script>
